import CONST from '../../helpers/const'

const catalog = {
  title: 'Product Catalog',
  detailTitle: 'Product Detail',
  companyName: '{company} catalog',
  placeholder: 'Enter a Model, Description, or Manufacturer',
  suggestionMsg: 'Sorry, no matching options',
  switcher: 'Tracked Products Only',
  viewModel: 'View this model.',
  allItem: 'All Items',
  allCategories: 'All Categories',
  equipment: 'Equipment',
  templateCategories: 'Template Categories',
  allProducts: 'All Products',
  tools: 'Tools & Equipment',
  materials: 'Materials & Consumables',
  allBrands: 'All Manufacturers',
  template: 'Template',
  allTemplates: 'All Templates',
  recentView: {
    header: 'Recently Viewed',
    noMsg: 'No Recently Viewed Items'
  },
  recentAdded: {
    header: 'Recently Added',
    noMsg: 'No Recently Added Items'
  },
  category: 'Categories',
  brand: 'Manufacturers',
  seeMore: 'See more',
  createItem: 'Create your own new item',
  addCategory: '... Add Category',
  addManufacturer: '... Add Manufacturer',
  searchItem: 'Search for an item or',
  dontSee: 'Don\'t see what you are looking for?',
  itemCnt: 'No Item tracked | 1 item tracked | {count} items tracked',
  itemStocked: 'Item Stocked',
  createModal: {
    title: 'Create New Model',
    duplicateMsg: 'A model with the name {modelName} already exists in your catalog.',
    dewaltMsg: 'UNABLE TO ADD ITEM. This item utilizes a DeWALT digital Unique Serial Number that is already in use. See {duplicatedModelNumber}, item number {duplicatedItemNumber}.',
    dewaltUSN: 'The model and serial number was obtained from the DeWALT USN.',
    tipMsg: 'Item quantity must be zero to unstock.',
    itemType: {
      label: 'Item Type',
      options: {
        0: 'Tool',
        1: 'Materials & Consumables',
        2: 'Equipment'
      }
    },
    trackingMethod: {
      label: 'Tracking Method',
      options: {
        0: 'Unique ID',
        1: 'Quantity'
      }
    },
    modalLabels: {
      0: 'Manufacturer',
      1: 'Brand',
      2: 'Category',
      3: 'Model',
      4: 'Catalog Number',
      5: 'UPC',
      6: 'Year',
      7: 'Description',
      8: 'Serial Number',
      9: 'Item Number',
      10: 'Barcode',
      11: 'Purchase Date',
      12: 'Purchase Cost'
    }
  },
  catalogDetail: {
    productInfo: {
      title: 'Product Information',
      labels: {
        0: 'Manufacturer',
        1: 'Model',
        2: 'Catalog Number',
        3: 'UPC/ASIN',
        4: 'Year',
        5: 'Manufacturer\'s Description',
        6: 'Manufacturer\'s Features'
      }
    },
    trackingInfo: {
      title: 'Tracking Information',
      labels: {
        0: 'Category',
        1: 'Generic Description',
        2: 'Item Type',
        3: 'Tracking Method',
        4: 'Default Class',
        5: 'Certifications',
        6: 'Meter Required',
        7: 'Model Id'
      },
      onTransfer: 'On transfer',
      period: ' | Every day | Every {count} days',
      meterRequiredMessage: 'Meter Required requires the model be Equipment Item Type. You can change this in the Item Type field above.'
    },
    noItemMsg: 'There are no items associated with the product.',
    bulkGroup: {
      0: 'Bulk Item Number',
      1: 'Bulk Item Barcode',
      2: 'Bulk Item Notes'
    },
    panels: {
      equivalentsList: {
        title: 'Product Equivalents'
      },
      stockList: {
        title: 'Stock List',
        itemFound: 'No item found | 1 item found | {count} items found'
      },
      itemList: {
        title: {
          0: 'Item List',
          1: 'Distributions'
        },
        tabs: {
          header: 'Item {name}',
          identification: {
            title: 'IDENTIFICATION',
            labels: {
              0: 'Item Number',
              1: 'Barcode Number',
              2: 'Serial Number',
              3: 'Electronic Scan',
              4: 'Notes'
            }
          },
          assignment: {
            title: 'ASSIGNMENT',
            labels: {
              0: 'Assigned To',
              1: 'Responsible Party',
              2: 'Personal Issue',
              3: 'Return Date',
              4: 'Status',
              5: 'Bill Location',
              6: 'In Kit',
              7: 'Class',
              8: 'Meter'
            }
          },
          ownership: {
            title: 'OWNERSHIP',
            labels: {
              0: 'Owner',
              1: 'Manager',
              2: 'Department'
            }
          },
          purchase: {
            title: 'PURCHASE',
            labels: {
              0: 'Purchase Date',
              1: 'Purchase Cost',
              2: 'Purchase Order',
              3: 'Warranty Expiration',
              4: 'Replacement Cost'
            }
          },
          vehicle: {
            title: 'EQUIPMENT',
            labels: {
              0: 'License Tag',
              1: 'State',
              2: 'Expiration',
              3: 'Fuel Card',
              4: 'Meter',
              5: 'Hours',
              6: 'VIN'
            }
          },
          leaseRental: {
            title: 'LEASE/RENTAL',
            lineActions: [
              {
                title: 'Add Contract',
                actionName: 'addContract'
              },
              {
                title: 'Edit Contract',
                actionName: 'editContract'
              }
            ]
          },
          customFields: {
            title: 'CUSTOM FIELDS'
          },
          gpsMap: {
            title: 'MAP'
          }
        }
      },
      cost: {
        title: 'Cost',
        labels: {
          0: 'MSRP',
          1: 'Replacement Cost',
          2: 'Unit of Measurement',
          3: 'Do not bill this model',
          4: 'Item Base Value Ea.',
          5: 'Preferred Vendor',
          6: 'Date'
        }
      },
      service: {
        title: 'SERVICE',
        servicePlanEventDetail: 'Service Plan Event Detail',
        selectServieEvent: 'Select Service Event(s)',
        eventRecurrence: 'Event Recurrence',
        recurrence: {
          type: {
            title: 'Recurrence Type',
            byDate: 'By Date',
            byMeter: 'By Meter',
            byHours: 'By Hours',
            byTurns: 'By Turns',
            occursFirst: 'Whichever Occurs First'
          },
          pattern: {
            title: 'Recurrence pattern',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly',
            weekday: 'Every weekday',
            every: 'Every',
            days: 'day(s)',
            recurEvery: 'Recur every',
            weeksOn: 'week(s) on',
            day: 'Day',
            ofEvery: 'of every',
            months: 'month(s)',
            the: 'The',
            of: 'of'
          },
          rangeOfRecurrence: {
            title: 'Range of recurrence',
            start: 'Start',
            noEndDate: 'No End Date',
            endAfter: 'End After',
            occurrence: 'occurrences',
            endBy: 'End by'
          },
          odomter: {
            title: 'Odometer / Meter Recurrence',
            startAt: 'Start At ...',
            repeatEvery: 'Repeat every ...',
            tooltip: 'Odometer/Meter Recurrence requires the model be Equipment Item Type. You can change this in the Model Detail in Item Type.'
          },
          hours: {
            title: 'Hours',
            startAt: 'Start At ...',
            repeatEvery: 'Repeat every ...',
            tooltip: 'Only available for Equipment type models.'
          },
          usageRecurrence: {
            title: 'Usage Recurrence',
            serviceAfterEvery: 'Service after every ...',
            assignments: 'assignments'
          },
          daysOfWeek: [
            {
              name: 'Sunday',
              value: CONST.SUNDAY
            },
            {
              name: 'Monday',
              value: CONST.MONDAY
            },
            {
              name: 'Tuesday',
              value: CONST.TUESDAY
            },
            {
              name: 'Wednesday',
              value: CONST.WEDNESDAY
            },
            {
              name: 'Thursday',
              value: CONST.THURSDAY
            },
            {
              name: 'Friday',
              value: CONST.FRIDAY
            },
            {
              name: 'Saturday',
              value: CONST.SATURDAY
            },
            {
              name: 'day',
              value: CONST.ALLDAYS
            },
            {
              name: 'weekday',
              value: CONST.WEEKDAY
            },
            {
              name: 'weekend day',
              value: CONST.WEEKENDDAY
            }
          ],
          weekNumber: [
            {
              name: 'first',
              value: 1
            },
            {
              name: 'second',
              value: 2
            },
            {
              name: 'third',
              value: 3
            },
            {
              name: 'fourth',
              value: 4
            },
            {
              name: 'last',
              value: 5
            }
          ],
          monthOfYear: [
            {
              name: 'January',
              value: 1
            },
            {
              name: 'February',
              value: 2
            },
            {
              name: 'March',
              value: 3
            },
            {
              name: 'April',
              value: 4
            },
            {
              name: 'May',
              value: 5
            },
            {
              name: 'June',
              value: 6
            },
            {
              name: 'July',
              value: 7
            },
            {
              name: 'August',
              value: 8
            },
            {
              name: 'September',
              value: 9
            },
            {
              name: 'October',
              value: 10
            },
            {
              name: 'November',
              value: 11
            },
            {
              name: 'December',
              value: 12
            }
          ],
          warning: {
            title: 'Appointment Recurrence',
            message: 'The "PatternDaysOfWeek" property cannot be set to "None" when the "PatternFrequency" property is set to "Weekly"'
          }
        },
        panels: {
          0: 'Service Plans',
          1: 'Open Work Orders',
          2: 'Work Order History',
          3: 'Repair Parts',
          4: 'Calibration Specs'
        },
        servicePlans: {
          tableHeader: {
            desktop: [
              {
                label: '',
                key: 'ServiceEventId',
                type: 'check'
              },
              {
                label: 'Name',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Recurring',
                key: 'IsRecurring',
                type: 'recurring',
                sortable: true
              },
              {
                label: 'Recurrence Method',
                key: 'RecurrenceMethod',
                type: 'recurringMethod',
                sortable: true
              },
              {
                label: 'Trigger On',
                key: 'TriggerOn',
                type: 'recurringTriggerOn',
                sortable: true
              },
              {
                label: 'Notes',
                key: 'Notes',
                sortable: true
              }
            ],
            mobile: [
              {
                label: '',
                key: 'ServiceEventId',
                type: 'check'
              },
              {
                label: 'Name',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Info',
                data: [{
                  label: 'Recurring',
                  key: 'IsRecurring',
                  type: 'recurring',
                  sortable: true
                },
                {
                  label: 'Recurrence Method',
                  key: 'RecurrenceMethod',
                  type: 'recurringMethod',
                  sortable: true
                },
                {
                  label: 'Trigger On',
                  key: 'TriggerOn',
                  type: 'recurringTriggerOn',
                  sortable: true
                },
                {
                  label: 'Notes',
                  key: 'Notes',
                  sortable: true
                }]
              }
            ]
          }
        },
        calibrationDlg: {
          title: 'New Calibration',
          labels: {
            description: 'Description',
            standard: 'Standard',
            min: 'Min',
            max: 'Max',
            unit: 'Unit'
          }
        },
        calibrationSpecDeleteDlg: {
          title: 'Delete...',
          msg: 'Are you sure you want to delete the calibration spec:'
        },
        servicePlanLineActions: [
          {
            title: 'Add',
            actionName: 'addServicePlan',
            isNoSelection: true,
            isMultiple: true
          },
          {
            title: 'Add Event From Model',
            actionName: 'addEventFromModel',
            isNoSelection: true,
            isShown: function (isItem) {
              return isItem
            }
          },
          {
            title: 'Edit',
            actionName: 'editServicePlan',
            isMultiple: false
          },
          {
            title: 'Delete',
            actionName: 'deleteServicePlan',
            isMultiple: true
          }
        ],
        repairPartsLineActions: [
          {
            title: 'Add',
            actionName: 'addRepairParts'
          },
          {
            title: 'Delete',
            actionName: 'deleteRepairParts'
          }
        ],
        calibrationSpecsLineActions: [
          {
            title: 'Add',
            actionName: 'addCalibrationSpecs',
            requiresSelection: false,
            allowsMultiSelect: true
          },
          {
            title: 'Delete',
            actionName: 'deleteCalibrationSpecs',
            requiresSelection: true,
            allowsMultiSelect: true
          }
        ],
        serviceEvents: {
          0: {
            planDetails: {
              title: 'Plan Details',
              labels: {
                0: 'Recurring',
                1: 'Comments'
              }
            }
          },
          1: {
            tasks: {
              title: 'Tasks',
              labels: {
                0: 'Type',
                1: 'Description',
                2: 'Est. Hours'
              }
            }
          },
          2: 'Instructions',
          3: {
            tools: {
              title: 'Tools',
              labels: {
                0: 'Description',
                1: 'Make',
                2: 'Model',
                3: 'Generic',
                4: 'Qty'
              }
            }
          },
          4: {
            parts: {
              title: 'Parts',
              labels: {
                0: 'Description',
                1: 'Item',
                2: 'Cost Ctr.',
                3: 'Cost Code',
                4: 'Qty'
              }
            }
          },
          5: {
            charges: {
              title: 'Charges',
              labels: {
                0: 'Type',
                1: 'Item',
                2: 'Description',
                3: 'Qty',
                4: 'Cost',
                5: 'Unit'
              }
            }
          }
        },
        openWorkOrders: {
          0: 'Number',
          1: 'Created',
          2: 'Priority',
          3: 'Status'
        },
        workOrderHistory: {
          0: 'Work Order',
          1: 'Created',
          2: 'Completed',
          3: 'Charges',
          4: 'Requested Service',
          5: 'Service Request Note'
        },
        repairParts: {
          tableHeader: {
            desktop: [
              {
                label: '',
                type: 'check',
                key: 'RecordId'
              },
              {
                label: 'Description',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Number',
                key: 'Number',
                sortable: true
              },
              {
                label: 'Manufacturer',
                key: 'Manufacturer',
                sortable: true
              },
              {
                label: 'Model',
                key: 'Model',
                sortable: true
              },
              {
                label: 'Required',
                key: 'Required',
                sortable: true
              },
              {
                label: 'Cost Ea.',
                key: 'Cost Ea.',
                sortable: true
              }
            ],
            mobile: [
              {
                label: '',
                type: 'check',
                key: 'RecordId'
              },
              {
                label: 'Description',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Info',
                data: [
                  {
                    label: 'Number',
                    key: 'Number',
                    sortable: true
                  },
                  {
                    label: 'Manufacturer',
                    key: 'Manufacturer',
                    sortable: true
                  },
                  {
                    label: 'Model',
                    key: 'Model',
                    sortable: true
                  },
                  {
                    label: 'Required',
                    key: 'Required',
                    sortable: true
                  },
                  {
                    label: 'Cost Ea.',
                    key: 'Cost Ea.',
                    sortable: true
                  }
                ]
              }
            ]
          }
        },
        calibrationSpecs: {
          tableHeader: {
            desktop: [
              {
                label: '',
                type: 'check',
                key: 'CalibrationSpecId'
              },
              {
                label: 'Description',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Min',
                key: 'StdMin',
                sortable: true
              },
              {
                label: 'Max',
                key: 'StdMax',
                sortable: true
              },
              {
                label: 'Unit',
                key: 'Unit',
                sortable: true
              }
            ],
            mobile: [
              {
                label: '',
                type: 'check',
                key: 'CalibrationSpecId'
              },
              {
                label: 'Description',
                key: 'Description',
                sortable: true
              },
              {
                label: 'Info',
                data: [
                  {
                    label: 'Min',
                    key: 'StdMin',
                    sortable: true
                  },
                  {
                    label: 'Max',
                    key: 'StdMax',
                    sortable: true
                  },
                  {
                    label: 'Unit',
                    key: 'Unit',
                    sortable: true
                  }
                ]
              }
            ]
          }
        }
      }
    }
  },
  meterDialog: {
    title: 'Meter Requirement Settings',
    labels: {
      0: 'Require meter reading every',
      1: 'Require meter reading on transfer'
    },
    days: ' | day | days'
  },
  attachment: {
    title: 'Attachments',
    labels: {
      0: 'Description',
      1: 'Last Updated',
      2: 'Updated By',
      3: 'Type',
      4: 'File',
      5: 'Web address (URL)',
      6: 'Location',
      7: 'Browse',
      8: 'Summary',
      9: 'New name'
    }
  },
  adjustForm: {
    title: 'Inventory Adjustment',
    labels: {
      0: 'Item:',
      1: 'Current Quantity',
      2: 'Adjusted Quantity',
      3: 'Adjustment Reason',
      4: 'Full Count Taken',
      5: 'Document'
    }
  },
  menu: {
    modelRecord: [
      {
        title: 'View Detail',
        isMultiple: false,
        isSingle: true,
        isTool: true,
        isMaterial: true,
        actionName: 'viewDetail'
      },
      {
        title: 'Copy Product',
        isMultiple: false,
        isSingle: true,
        isTool: true,
        isMaterial: true,
        actionName: 'copyModel'
      },
      {
        title: 'Merge Products',
        isMultiple: true,
        isSingle: false,
        isTool: true,
        isMaterial: true,
        actionName: 'mergeModel'
      },
      {
        title: 'Remove Product',
        isMultiple: false,
        isSingle: true,
        isTool: true,
        isMaterial: true,
        actionName: 'removeModel'
      },
      {
        title: 'Merge Duplicates',
        isMultiple: false,
        isSingle: true,
        isTool: true,
        isMaterial: true,
        actionName: 'mergeDuplicates'
      }
    ],
    equivalentsList: [
      {
        title: 'Add Equivalent'
      }
    ],
    stockList: [
      {
        title: 'Add Stock Info',
        actionName: 'addStock',
        isNoSelection: true,
        isSingle: false,
        isMultiple: false,
        isTool: false,
        isQuantityType: true,
        isMaterial: true
      }
    ],
    toolList: [
      {
        title: 'Add Item',
        actionName: 'addItem',
        isTool: true,
        isQuantityType: true,
        isMaterial: true,
        requiresSelection: false,
        allowsMultiSelect: true
      },
      {
        title: 'Edit Item',
        actionName: 'editItem',
        isTool: true,
        isQuantityType: true,
        isMaterial: true,
        requiresSelection: true,
        allowsMultiSelect: false
      }
    ]
  },
  subActionHeaderForCatalogHomeAndResult: {
    0: {
      title: 'Home',
      icon: 'fas fa-home'
    },
    1: {
      title: 'Add Model',
      icon: 'fas fa-plus'
    }
  },
  subActionHeaderForCatalogDetail: {
    0: {
      title: 'Back',
      icon: 'fas fa-undo'
    },
    1: {
      title: 'Attachments',
      icon: 'fas fa-paperclip'
    },
    2: {
      title: 'Add Item',
      icon: 'fas fa-plus'
    },
    3: {
      title: 'Add Stock',
      icon: 'fas fa-plus'
    }
  },
  mergeModels: {
    warningTitle: 'Unable to merge Unique Tool Models.',
    warningMessage: 'Unable to merge Quantity Tracked Tool Models. \n Please restrict your selection to only Unique Tool Models.'
  },
  mergeDuplicates: {
    quantityItemsOnly: 'Can only merge duplicate items for quantity models',
    noDuplicates: 'MODEL has no duplicate inventory records to merge.',
    duplicatesExist: 'MODEL has duplicate inventory records with different item numbers and/or barcodes. Select the item that should remain after merging.',
    confirmMerge: 'ITEMNUMBER / BARCODE will be the remaining number and barcode after merging',
    openBillingWarning: 'Merge inventory records for MODEL.\nAny open billing streams on merged items will be closed by this process.\nPlease allow several minutes for the results to appear in the Tool Browser and the Model Record\'s Tool List.\nContinue with Merge?'
  },
  dlgDeleteCatalogModelConfirm: {
    activeTitle: 'Delete Active Model?',
    title: 'Delete...',
    activeMessage: 'This Model has active records associated with it. Are you sure you want to delete it?',
    message: 'Are you sure you want to delete model: '
  },
  catlogDragPaste: {
    pastedragText: 'or Drag and Drop, Copy and Paste Files',
    btnText: 'Upload New Image',
    dropText: 'Drop Here'
  }
}

export default catalog
